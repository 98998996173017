import React from 'react'
import { Link } from 'react-router-dom';
import './Bottomnav.css'


const Bottomnav = () => {
  return (
<div className="bottom">
<div className="active1">
<Link to="/home">
   <a href="">Hotels</a>
</Link>
</div>
<div>
<Link to="/activites">
   <a href="">Activites</a>
</Link>
</div>

<div>
<Link to="/destination">
   <a href="">Destination</a>
</Link>
</div>
<div>
<Link to="/Events">
   <a href="">Events</a>
</Link>
</div>
<div>
<Link to="/Artists">
   <a href="">Artists</a>
</Link>
</div>

<div className='holiday'>
<Link to="/holidaydeals">
   <a href="">HolidayDeals</a>
</Link>
</div>
<div className='group'> 
<Link to="/grouptravel">
   <a href="">GroupTravel</a>
</Link>
</div>
<div>
<Link to="/Events">
   <a href="">Faculty</a>
</Link>
</div>
<div>
<Link to="/Events">
   <a href="">Outlines</a>
</Link>
</div>
<div>
<Link to="/Events">
   <a href="">Gifting</a>
</Link>
</div>
</div>
  )
}

export default Bottomnav;