import React from 'react'
import './Buttoon.css'
function Buttoon (props) {
  return (
    <>
    <button className='bttn'>{props.btntext}</button>
    </>
  )
}

export default Buttoon;