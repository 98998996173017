import React from 'react'
import Navbaar from '../Navbaar';
import Footer from './Footer';
import './Events.css'
import Bottomnav from '../Component/Bottomnav';
import EventCard from '../Component/EventCard';
import Drpdwn2 from '../Component/Dropdwn2';

const Events = () => {
  return (
  <>
  <Navbaar/>

<div class="contain">
  {/* <video autoPlay loop muted>
  <source src="./Videos/events.mp4" type="video/mp4"/>
  Your browser does not support the video tag.
</video> */}
<img src="./images/V4-5.png" alt="" />
<Bottomnav/>
<div className="bottomobile">
  <Drpdwn2/>
</div>

</div>
{/*  */}
<header>
            <h1>
                Events
            </h1>
        </header>
<div className='activity-cards'>

<EventCard
Eventimg='./images/event1.jpeg'
cardname='Corporate Event'
more='Corporate Family Day'
/>
<EventCard
Eventimg='./images/event1.jpeg'
cardname='Corporate Event'
more='Corporate Family Day'
/>

<EventCard
Eventimg='./images/event1.jpeg'
cardname='Corporate Event'
more='Corporate Family Day'
/>
<EventCard
Eventimg='./images/event1.jpeg'
cardname='Corporate Event'
more='Corporate Family Day'
/><EventCard
Eventimg='./images/event1.jpeg'
cardname='Corporate Event'
more='Corporate Family Day'
/>
<EventCard
Eventimg='./images/event1.jpeg'
cardname='Corporate Event'
more='Corporate Family Day'
/>



            

</div>


   <Footer/>
  </>
  )
}

export default Events;