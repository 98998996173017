import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

function Drpdwn2() {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic">
      Services
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Dropdown.Item > <Link to="/home">Hotels</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="/activites">Activities</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="/destination">Destination</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="/events">Events</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="Artists">Artists</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="">HolidayDeals</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="">GroupTravel</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="">Faculty</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="">Outlines</Link> </Dropdown.Item>
        <Dropdown.Item > <Link to="">Gifting</Link> </Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Drpdwn2;