import React from 'react'

function DestinationCard (props) {
  return (
    <>
         <div className="card">
                    <img src={props.activityimg} alt="Avatar" />
                    <div className="cont">
                        <h4><b>{props.cardname}</b></h4>
                        <p>{props.timedatefilter}</p>
                        <p>{props.amount}</p>
                        <button className='destination-button'>Book Now</button>
                    </div>
                </div>
                
    </>
  )
}

export default DestinationCard;
