import React from 'react'
import Navbaar from '../Navbaar';
import Footer from './Footer';
import './Events.css'
import Bottomnav from '../Component/Bottomnav';
import EventCard from '../Component/EventCard';
import Drpdwn2 from '../Component/Dropdwn2';

const GroupTravel = () => {
  return (
    <>
    <Navbaar/>
  
  <div class="contain">
    {/* <video autoPlay loop muted>
    <source src="./Videos/GT.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video> */}
  <img src="./images/V7.png" alt="" />
  <Bottomnav/>
  <div className="bottomobile">
    <Drpdwn2/>
  </div>
  
  </div>
  {/*  */}
  <header>
              <h1>
                All Deals
              </h1>
          </header>
  <div className='activity-cards'>
  
  <EventCard
  Eventimg='./images/train.png'
  cardname='Train Travel'
  more='Group Traveling'
  />
  <EventCard
  Eventimg='./images/flight.png'
  cardname='Flight Travel'
  more='Group Traveling'
  />
  
  <EventCard
  Eventimg='./images/bus.png'
  cardname='Bus Travel'
  more='Group Traveling'
  />
  <EventCard
  Eventimg='./images/car.png'
  cardname='car Travel'
  more='Group Traveling'
  />

  </div>
  
  
     <Footer/>
    </>
  )
}

export default GroupTravel

