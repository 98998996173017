import React from 'react';
import { Link } from 'react-router-dom';
import Navbaar from '../Navbaar';
import './Signup.css'




class Partner extends React.Component{
  render(){
    return(
        <>
             <Navbaar/>
              <div id="partner">
        <Form />

      </div>
        </>

    )
  }
}

const FormHeader = props => (
    <h2 id="headerTitle">{props.title}</h2>
);


const Form = props => (
   <div>
<Select description="Partner with us as"
            value1="Trainer"
            value2="Hotel"
            value3="Transporter"
            value4="Events"
            value5="Corporate Gifting"
            value6="Artist"
            value7="Live Performance"
        />
     <FormInput description="Contact Number" placeholder="Enter Contact Number" type="tel"/>
     <FormInput description="Email" placeholder="Enter Email Id" type="email"/>
     <Link to="/business-contact">
          <FormButton title="Next" />
              </Link>
   </div>
);

const FormButton = props => (
  <div id="button" class="row">
    <button>{props.title}</button>
  </div>
);

const FormInput = props => (
  <div class="row">
    <label>{props.description}</label>
    <input type={props.type} placeholder={props.placeholder}/>
  </div>  
);

const OtherMethods = props => (
  <div id="alternativeLogin">
    <label>Sign up</label>
    <div id="iconGroup">
     
    </div>
  </div>
);

const Select = props => (
  <div class="row-1">
      <label>{props.description}</label>
      <select id="cars" name="cars">
          <option value={props.value1}>{props.value1}</option>
          <option value={props.value2}>{props.value2}</option>
          <option value={props.value3}>{props.value3}</option>
          <option value={props.value4}>{props.value4}</option>
          <option value={props.value5}>{props.value5}</option>
          <option value={props.value6}>{props.value6}</option>
          <option value={props.value7}>{props.value7}</option>

     

      </select>
  </div>
);


export default Partner;