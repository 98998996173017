import React from 'react'
import Card from './Card'
import DestinationCard from './Card-2'
import './Scroller.css'

const DestinationScroller = () => {
    return (
        <>    <header>
            <h1>
            Popular Destination
            </h1>
        </header>
            <div class="cardScrool">

           <DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />
                 <DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />
                 <DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />
                 <DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />
                 <DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />
                 <DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />
                 <DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />
                 <DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />
            </div>
        </>
    )
}

export default DestinationScroller