import React from 'react';
import Navbaar from '../Navbaar';
import './Signup.css'




class Query extends React.Component {
    render() {
        return (
            <>
                <Navbaar />
                <br />
                <br />
                <br />
                <div id="Queryform">
                    <Form />
                </div>
            </>

        )
    }
}




const Form = props => (
    <div>
        <FormInput description="Name" placeholder="Enter your Name" type="text" />
        <FormInput description="Email" placeholder="Enter your Email " type="email" />
        <MsgQuery description="Message" />
        <FormButton title="Send" />
    </div>
);

const FormButton = props => (
    <div id="button" class="row">
        <button>{props.title}</button>
    </div>
);

const FormInput = props => (
    <div class="row">
        <label>{props.description}</label>
        <input type={props.type} placeholder={props.placeholder} />
    </div>
);

const MsgQuery = props => (
    <div class="row">
        <label>{props.description}</label>
        <textarea id="w3review" name="w3review" rows="4" cols="10">Write Your Query</textarea>
    </div>
);



export default Query;