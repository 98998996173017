import React from 'react';
import { Link } from 'react-router-dom';
import Navbaar from '../Navbaar';
import './Signup.css'




class LogIn extends React.Component {
  render() {
    return (
      <>
      <div className='loginimg'>
      <Link to="/home">
            <img src="./images/Logo.png" alt="" />
          </Link>
      </div>


        <div id="loginform">
          <FormHeader title="Log in" />
          <Form />
        </div>
      </>

    )
  }
}

const FormHeader = props => (
  <>
    <h2 id="headerTitle">{props.title}</h2>

  </>
);


const Form = props => (
  <div>
    <FormInput description="Username" placeholder="Enter your username" type="text" />
    <FormInput description="Password" placeholder="Enter your password" type="password" />
    <FormButton title="Log in" />
    <Link to="/forget-password" className='otherm'>
      Forgotten account?
    </Link>

    <Link to="/create-account" className='otherm'>
      Create a new account?
    </Link>
  </div>
);

const FormButton = props => (
  <div id="button" class="row">
    <button>{props.title}</button>
  </div>
);

const FormInput = props => (
  <div class="row">
    <label>{props.description}</label>
    <input type={props.type} placeholder={props.placeholder} />
  </div>
);




export default LogIn;

