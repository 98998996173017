import Dropdown from 'react-bootstrap/Dropdown';

function Drpdwn() {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic">
      Categories
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Dropdown.Item href="#/action-1">Show all</Dropdown.Item>
        <Dropdown.Item href="#/action-1">farm House</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Camps</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Beach Side</Dropdown.Item>
        <Dropdown.Item href="#/action-3">River Side</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Mountain</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Conference</Dropdown.Item>
        <Dropdown.Item href="#/action-3">International</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Resort</Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Drpdwn;