import React from 'react';
import { Link } from 'react-router-dom';
import Navbaar from '../Navbaar';
import './BussCont.css'




class BussCont extends React.Component {
    render() {
        return (
            <>
                <Navbaar />
                <div id="BussCont">
                    <Form />

                </div>
            </>

        )
    }
}

const FormHeader = props => (
    <h2 id="headerTitle">{props.title}</h2>
);


const Form = props => (
    <>
        <FormInput description="Business Name" placeholder="Enter Business name" type="text" />
        <table>
            <tr>
                <th>
                    <FormInput description="Contact Person 1" placeholder="Mobile no." type="tell" />
                </th>
                <th>
                    <FormInput description="WhatsApp No." placeholder="Enter WhatsApp No." type="text" />
                </th>
            </tr>
            <tr>

                <th>
                    <FormInput description="Alternate Landline No." placeholder="Land Line No" type="text" />
                </th>
                <th>
                    <FormInput description="Email Id" placeholder="Official Email id" type="tell" />
                </th>
            </tr>
            <tr>

                <th>
                    <FormInput description="Contact Person 2" placeholder="Mobile no." type="tell" />
                </th>
                <th>
                    <FormInput description="Whats app No." placeholder="Mobile no." type="text" />
                </th>
            </tr>
            <tr>

                <th>
                    <FormInput description="Alternate Landline No." placeholder="Land Line No" type="text" />
                </th>
                <th>
                    <FormInput description="Email Id" placeholder="Official Email id" type="tell" />
                </th>
            </tr>



        </table>


        <FormInput description="Business Address" placeholder="Supply Address" type="text" />
        <Select description="Country"
            value1="India"
            value2="Srilanka"
            value3="Malyasia"
            value4="Dubai"
            value5="Singapore"
        />
             <Select description="State"
            value1="Delhi NCR"
            value2="Karnataka"
            value3="Kerela"
            value4="etc "
            value5="etc "
        />

<Select description="City"
            value1="Noida"
            value2="Gurgaon"
            value3="Faridabad"
            value4="Gaziabad "
            value5="Delhi "
        />
          <FormInput description="Pin Code" placeholder="Pin Code" type="text" />
      <Link to='/bank-detail'>
        <FormButton title="Next" />
       </Link>
    </>
);

const FormButton = props => (
    <div id="button" class="row-1">
        <button>{props.title}</button>
    </div>
);

const FormInput = props => (
    <div class="row-1">
        <label>{props.description}</label>
        <input type={props.type} placeholder={props.placeholder} />
    </div>
);
const Select = props => (
    <div class="row-1">
        <label>{props.description}</label>
        <select id="cars" name="cars">
            <option value={props.value1}>{props.value1}</option>
            <option value={props.value2}>{props.value2}</option>
            <option value={props.value3}>{props.value3}</option>
            <option value={props.value4}>{props.value4}</option>
            <option value={props.value5}>{props.value5}</option>
            <option value={props.value6}>{props.value6}</option>
       

        </select>
    </div>
);

const OtherMethods = props => (
    <div id="alternativeLogin">
        <label>Sign up</label>
        <div id="iconGroup">

        </div>
    </div>
);




export default BussCont