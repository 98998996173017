import React from 'react'


function EventCard (props) {
    return (
      <>
           <div className="card">
                      <img src={props.Eventimg} alt="Avatar" />
                      <div className="cont">
                          <h5>{props.cardname}</h5>
                          <p>{props.more}</p>
                        
                      </div>
                  </div>
                  
      </>
    )
  }
  
  export default EventCard;