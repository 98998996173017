import React from 'react'
import Navbaar from '../Navbaar';
import Footer from './Footer';
import Bottomnav from '../Component/Bottomnav';

import './Home.css'
import Scroller from '../Component/Scroller';
import DestinationScroller from '../Component/Scroller-2';
import Drpdwn2 from '../Component/Dropdwn2';


const Home = () => {
  return (
  <>
  <Navbaar/>

<div class="contain">
  {/* <video autoPlay loop muted>
  <source src="./Videos/Beach.mp4" type="video/mp4"/>
  Your browser does not support the video tag.
</video> */}
<img src="./images/V1.png" alt="" />
<h1 class="top-left">Choose Your <br />
Experience</h1>
<Bottomnav/>
<div className="bottomobile">
  <Drpdwn2/>
</div>
</div>
<div className='home-icon'>
<i class="bi bi-geo-alt-fill item1">&nbsp;&nbsp; Destinations</i>
<i class="bi bi-calendar-fill item1">&nbsp;&nbsp;Check in - Check OUT</i>
<i class="bi bi-people-fill item1">&nbsp;&nbsp;Guests</i>
<i class="bi bi-plus-circle-fill item1">&nbsp;&nbsp;More</i>
<i class="bi bi-search item1"></i>
</div>
<Scroller/>
<DestinationScroller/>
<br /><br />
   <Footer/>
  </>
  )
}

export default Home;