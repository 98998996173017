import React from 'react'
import Navbaar from '../Navbaar';
import Footer from './Footer';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Events.css'
import DestinationCard from '../Component/Card-2';
import Bottomnav from '../Component/Bottomnav';
import Drpdwn2 from '../Component/Dropdwn2';

const HolidayDeals = () => {
  return (
    <>
    <Navbaar/>
  
  <div class="contain">
    {/* <video autoPlay loop muted>
    <source src="./Videos/Video.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video> */}
  <img src="./images/V6.png" alt="" />
  <Bottomnav/>
<div className="bottomobile">
  <Drpdwn2/>
</div>
  </div>
  {/* mobile view bottom navbar */}
     
  <header>
              <h1>
              Best Selling Destinations
              </h1>
          </header>
  <div className='activity-cards'>
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  
              
  
  </div>


     
  <header>
              <h1>
              Easy Visa Destinations
              </h1>
          </header>
  <div className='activity-cards'>
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  <DestinationCard
             activityimg='./images/Dimg.png'
             cardname='Goa'
             timedatefilter='6 Hours | Dec-Mar'
             amount='50,000'
             />
  
  
              
  
  </div>
  
  
     <Footer/>
    </>
  )
}

export default HolidayDeals;