import React from 'react'
import './Footer.css'
const Footer = () => {
    return (
        <>
           


<div class="flex-container">
  <div class="flex-item-left">Copyright @ 2022. All Right Reserved. 
by Codeedge Technology</div>
  <div class="flex-item-right">
  <i class="bi bi-dribbble"></i>
  <i class="bi bi-twitter"></i>
  <i class="bi bi-google"></i>
  <i class="bi bi-facebook"></i>
  <i class="bi bi-instagram"></i>
  </div>
</div>
        </>
    )
}

export default Footer;