import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Card from './Card'
import './Scroller.css'

function Scroller() {
    const [data, setData] = useState([])
    useEffect(() => {
        fetch("https://codeedge.in/tacAdventure/api/activity").then((result) => {
            result.json().then((resp) => {
                setData(resp)
            })
        })
    }, [])
    console.warn(data)
    return (
        <>
            <header>
                <h1>
                    Popular Activites
                </h1>
            </header>
            <div class="cardScrool">



                {

                    data.map((item) =>
                        <Link to={`/Activity-filter/${item.id}`} >
                            <div className="card" >
                                <img src={`https://codeedge.in/tacAdventure/activityimage/${item.image1}`} alt="Avatar" />
                                <div className="cont">
                                    <h4><b>{item.activityName}</b></h4>
                                    <i class="bi bi-geo-alt-fill"></i> {item.location}<br />
                                    <img style={{width: "20px" , height: "20px"}} src="./images/inr.svg" alt="" /> {item.price_max} <br />
                                    <i class="bi bi-people-fill"></i>{item.people_max}<br />
                                    <i class="bi bi-clock-fill"></i> 6 Hours<br />
                                </div>
                            </div>
                        </Link>

                    )
                }
            </div>
        </>
    )
}

export default Scroller


