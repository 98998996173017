import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import './cards.css'

function Gridcard() {
  return (
    <CardGroup>
      <Card>

        <div className='box ' style={{ backgroundImage: "url(images/c-b-1.png)" }}>
          <Card.Title> <h3> Drop on in</h3></Card.Title>
          <Card.Text>

            E -6, 4th Floor, Main Road, Kalkaji,
            New Delhi - 110019
          </Card.Text>
        </div>
      </Card>
      <Card>


        <div className='box ' style={{ backgroundImage: "url(images/c-b-2.png)" }}>
          <Card.Title><h3> Give Us a Call</h3></Card.Title>
          <Card.Text>

            Mobile: 011 - 65260123,
            65260202
          </Card.Text>
        </div>
      </Card>
      <Card>

        <div className='box ' style={{ backgroundImage: "url(images/c-b-3.png)" }}>
          <Card.Title><h3>Connect Online</h3> </Card.Title>
          <Card.Text>
            Email: info@tac.com
          </Card.Text>
        </div>

      </Card>
    </CardGroup>
  );
}

export default Gridcard;