import React from 'react';
import { Link } from 'react-router-dom';
import Navbaar from '../Navbaar';
import './BussCont.css'




class BankDetail extends React.Component {
    render() {
        return (
            <>
                <Navbaar />
                <div id="BussCont">

                    <FormHeader title="Registeration" />
                    <Form1 />
                    <br />
                    <FormHeader title="Bank Details" />
                    <Form2/>

                    <FormButton title="Next"/>
                </div>
       



            </>

        )
    }
}




// 


const FormHeader = props => (
    <h2 id="headerTitle">{props.title}</h2>
);


const Form1 = props => (
    <>
        <div>
            <FormInput description="GST No." placeholder="Enter GST Number" type="text" />
            <FormUpload title="Upload GST File" />
        </div>
        <div>
            <FormInput description="Pan No." placeholder="Enter Pan Number" type="text" />
        </div>

    </>
);

const Form2 = props => (
    <>
        <div>
            <FormInput description="Business Name in Bank" placeholder="Enter Business name in Bank" type="text" />
        </div>
        <div>
            <FormInput description="Bank Name" placeholder="Enter Bank Name" type="text" />
        </div>
        <div>
            <FormInput description="A/c No." placeholder="Enter Account No." type="text" />
            <FormUpload title="Upload A/c No. File" />
        </div>
        <div>
        <FormInput description="IFSC Code" placeholder="Enter IFSC Code" type="text" />
        </div>

    </>
);


const FormUpload = props => (
    <div>
        <form action="/action_page.php">
            <label for="img">{props.title}</label> <br />
            <input type="file" id="img" name="img" accept="image/*" />
        </form>
    </div>
);

const FormButton = props => (
    <div id="button" class="row-1">
        <button>{props.title}</button>
    </div>
);

const FormInput = props => (
    <div class="row-1">
        <label>{props.description}</label>
        <input type={props.type} placeholder={props.placeholder} />
    </div>
);








export default BankDetail




