import React from 'react';
import { Link } from 'react-router-dom';

import './Signup.css'




class Forget extends React.Component {
  render() {
    return (
      <>
        <div className='loginimg'>
          <Link to="/home">
            <img src="./images/Logo.png" alt="" />
          </Link>
        </div>


        <div id="loginform">
          <FormHeader title="Change Password" />
          <Form />


        </div>

      </>

    )
  }
}

const FormHeader = props => (
  <>
    <h2 id="headerTitle">{props.title}</h2>

  </>
);


const Form = props => (
  <div>
    <FormInput description="Username" placeholder="Enter your username" type="text" />
    <FormInput description="New Password" placeholder="" type="password" />
    <FormInput description="Confirm Password" placeholder="" type="password" />
    <Link to="/reset" >
      <FormButton title=" Confirm" />
    </Link>
    <Link to="/" className='otherm'>
      Back
    </Link>
  </div>
);

const FormButton = props => (
  <div id="button" class="row">
    <button>{props.title}</button>
  </div>
);

const FormInput = props => (
  <div class="row">
    <label>{props.description}</label>
    <input type={props.type} placeholder={props.placeholder} />
  </div>
);




export default Forget;

