import React from 'react';
import { Link } from 'react-router-dom';
import Navbaar from '../Navbaar';
import './BussCont.css'

class TrainerPageForm extends React.Component {
    render() {
        return (
            <>
                <Navbaar />
                <div id="BussCont">

                    <FormHeader title="Trainer" />
                    <Form1 />

                </div>




            </>

        )
    }
}




// 


const FormHeader = props => (
    <h2 id="headerTitle">{props.title}</h2>
);


const Form1 = props => (
    <>
        <div>
            <label> Gender</label> <br />
            <input type="radio" name="gender" value="male" /> Male &nbsp;
            <input type="radio" name="gender" value="female" /> Female
        </div>
        <br />
        <div>
            <label>DOB</label> <br />

            <input type="date" id="start" name="trip-start"
                value="2018-07-22"
                min="2018-01-01" max="2018-12-31" />
        </div>
        <br />
        <h2 id="headerTitle">Tell us a bit more about you</h2>
        <br />
        <div>
            <label>Are you a certified coach</label> <br />
            <label>
                Yes or no? &nbsp;
                <select>
                    <option value="">---</option>
                    <option value="">Yes</option>
                    <option value="">No</option>
                </select>
            </label>
        </div>
        <br />
        <div>
            <label> Total work experience </label>&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="number" placeholder="Select year's of experience" />
        </div>
        <br />
        {/* Industry Experience */}
        <div>
            <label htmlFor="">Industry Experience</label> <br />
            <table>

                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>


                <input type="submit" value="Apply" />
            </table>

        </div>

        <br /><br />


        
        {/* Programs Delivered */}
        <div>
            <label htmlFor="">Industry Experience</label> <br />
            <table>

                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>
                <tr>

                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                    <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>  <th>
                        <input type="checkbox" id="Industry Experience" value="Auto" />&nbsp;&nbsp;
                        Auto
                    </th>
                </tr>


                <input type="submit" value="Apply" />
            </table>

        </div>

    </>
);




const FormUpload = props => (
    <div>
        <form action="/action_page.php">
            <label for="img">{props.title}</label> <br />
            <input type="file" id="img" name="img" accept="image/*" />
        </form>
    </div>
);

const FormButton = props => (
    <div id="button" class="row-1">
        <button>{props.title}</button>
    </div>
);





const FormInput = props => (
    <div class="row-1">
        <label>{props.description}</label>
        <input type={props.type} placeholder={props.placeholder} value={props.value} name={props.name} />{props.caption}
    </div>
);









export default TrainerPageForm




