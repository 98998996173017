import React from 'react'

function Card (props) {
  return (
    <>
        
         <div className="card">
                    <img src={props.activityimg} alt="Avatar" />
                    <div className="cont">
                        <h4><b>{props.cardname}</b></h4>
                        <i class="bi bi-geo-alt-fill"></i> {props.location}<br />
                        <img style={{width: "20px" , height: "20px"}} src="./images/inr.svg" alt="" /> {props.rupee} <br />
                        <i class="bi bi-people-fill"></i> {props.persion}<br />
                        <i class="bi bi-clock-fill"></i>  {props.duration}<br />
                    </div>
                </div>
                
    </>
  )
}

export default Card