import React from 'react'
import Navbaar from '../Navbaar';
import Footer from './Footer';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Events.css'
import DestinationCard from '../Component/Card-2';
import Drpdwn from '../Component/Dropdwn';


const Destination = () => {
  return (
  <>
  <Navbaar/>

<div class="contain">
  {/* <video autoPlay loop muted>
  <source src="./Videos/Destination.mp4" type="video/mp4"/>
  Your browser does not support the video tag.
</video> */}
<img src="./images/V3.png" alt="" />
<div className="bottom-1">
<div className="active1">
<a href="">Show&nbsp;all</a>
</div>
<div>
<a href="">Farm&nbsp;House</a>
</div>
<div>
<a href="">Camps</a>
</div>

<div className='holiday'>
<a href="">5&nbsp;star</a>
</div>
<div className='group'> 
<a href="">Beach&nbsp;Side</a>
</div>
<div>
<a href="">River&nbsp;Side</a>
</div>
<div>
<a href="">Mountain</a>
</div>
<div>
<a href="">Conference </a>
</div>
<div>
<a href="">International</a>
</div>
<div>
<a href="">Resort</a>
</div>
</div>
<div className="bottomobile">
  <Drpdwn/>
</div>
</div>
{/* mobile view bottom navbar */}
   
<header>
            <h1>
            Popular Destination
            </h1>
        </header>
<div className='activity-cards'>

<DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />


<DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />

<DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />

<DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />

<DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />

<DestinationCard
           activityimg='./images/Dimg.png'
           cardname='Goa'
           timedatefilter='6 Hours | Dec-Mar'
           amount='50,000'
           />


            

</div>


   <Footer/>
  </>
  )
}

export default Destination;