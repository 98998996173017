
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import LogIn from './pages/LogIn';
import Activites from './pages/Activites'
import Contact from './pages/Contact';
import Events from './pages/Events';
import Artists from './pages/Artists';
import Destination from './pages/Destination';
import HolidayDeals from './pages/HolidayDeals';
import GroupTravel from './pages/GroupTravel';
import Partner from './pages/Partner';
import BussCont from './pages/BussCont';
import Query from './pages/Query';
import CreateAccount from './pages/CreateAccount';
import Forget from './pages/Forget';
import BankDetail from './pages/BankDetail';
import Reset from './pages/Reset'
import Modal from './pages/Modal';
import TrainerPageForm from './pages/TrainerPageForm';
import ActivityFilter from './pages/ActivityFilter';



const App = () => {
    return ( 
        <>
        <Router>
        <Routes>
             <Route path='/' element={<Home/>} />
            <Route exact path='/home' element={<Home/>} />  
            <Route exact path='/login' element={<LogIn/>} /> 
            <Route exact path='/contact' element={<Contact/>} />   
            <Route exact path='/activites' element={<Activites/>} /> 
            <Route exact path='/destination' element={<Destination/>} /> 
            <Route exact path='/artists' element={<Artists/>} /> 
            <Route exact path='/events' element={<Events/>} /> 
            <Route exact path='/holidaydeals' element={<HolidayDeals/>} /> 
            <Route exact path='/grouptravel' element={<GroupTravel/>} /> 
            <Route exact path='/partner' element={<Partner/>} /> 
            <Route exact path='/business-contact' element={<BussCont/>} />
            <Route exact path='/query' element={<Query/>} />  
            <Route exact path='/create-account' element={<CreateAccount/>} />
            <Route exact path='/forget-password' element={<Forget/>} />
            <Route exact path='/reset' element={<Reset/>} />
            <Route exact path='/bank-detail' element={<BankDetail/>} />
            <Route exact path='/modal' element={<Modal/>} />
            <Route exact path='/trainer-form' element={<TrainerPageForm/>} />
            <Route exact path='/Activity-filter/:id' element={<ActivityFilter/>} />
        </Routes>
        </Router>
   
     
        </>
    )
}

export default App;

