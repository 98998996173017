import React from 'react'
import Navbaar from '../Navbaar';
import Footer from './Footer';
import './Events.css'
import Bottomnav from '../Component/Bottomnav';
import EventCard from '../Component/EventCard';

const Artists = () => {
  return (
  <>
       <Navbaar/>

<div class="contain">
  {/* <video autoPlay loop muted>
  <source src="./Videos/events.mp4" type="video/mp4"/>
  Your browser does not support the video tag.
</video> */}
<img src="./images/V4-5.png" alt="" />
<Bottomnav/>
</div>
{/*  */}
<header>
            <h1>
              All Artists
            </h1>
        </header>
<div className='activity-cards'>

<EventCard
Eventimg='./images/salmonboi.jpeg'
cardname='Salman Khan'
more='Mumbai'
/>
<EventCard
Eventimg='./images/saroj.jpeg'
cardname='Saroj Khan'
more='Mumbai'
/>

<EventCard
Eventimg='./images/neha.jpeg'
cardname='Neha kakkar'
more='Mumbai'
/>

<EventCard
Eventimg='./images/saroj.jpeg'
cardname='Saroj Khan'
more='Mumbai'
/>
<EventCard
Eventimg='./images/saroj.jpeg'
cardname='Saroj Khan'
more='Mumbai'
/>
            

</div>


   <Footer/>
  
  </>
  )
}

export default Artists