import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Buttoon from './Component/Buttoon';
import { Link } from 'react-router-dom';
import './Navbar.css'

function Navbaar() {
  return (
    <Navbar className='border-bottom navu ' fixed="top" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <Link to="/home" className="home">
            <img src="./images/Logo.png" alt="" />
          </Link>

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className='flex-grow-0' id="basic-navbar-nav">
          <Nav className="me-5 ">
          <Nav.Link>
              <Link to="/query">
                Query
              </Link>
            </Nav.Link>



            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/home">
                    Hotels
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/activites">
                  Activites
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/destination">
                  Destination
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/events">
                  Events
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/Artists">
                  Artists
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/holidaydeals">
                Holiday Deals 
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/grouptravel">
                  Group Travel
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/home">
                  Faculty
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/home">
                  Outlines
                </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                <Link to="/home">
                  Gifting
                </Link>
              </NavDropdown.Item>

            </NavDropdown>

            <Nav.Link>
              <Link to="/partner">
                Partner
              </Link>
            </Nav.Link>

            <Nav.Link>  <Link to="/Contact" className="Contactus">
              Contact us
            </Link></Nav.Link>
         
          </Nav>
          <Nav>
            <Link to="/login">
              <Buttoon btntext="Log in" />
            </Link>
          </Nav>
        </Navbar.Collapse>

      </Container>

    </Navbar>
  );
}

export default Navbaar;