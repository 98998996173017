import React from 'react'
import Navbaar from '../Navbaar';
import Footer from './Footer';
import './Activity.css'
import Card from '../Component/Card';
import Drpdwn3 from '../Component/Dropdwn3';

const Activity = () => {
  return (
  <>
  <Navbaar/>

<div class="contain">
  {/* <video autoPlay loop muted>
  <source src="./Videos/Activity.mp4" type="video/mp4"/>
  Your browser does not support the video tag.
</video> */}
<img src="./images/V2.png" alt="" />
<div className="bottom-1">
<div className="active1">
<a href="">Show all    </a>
</div>
<div>
<a href="">Indoor Activity</a>
</div>
<div>
<a href="">Outdoor Activity</a>
</div>

<div className='holiday'>
<a href="">Culinary Activity</a>
</div>
<div className='group'> 
<a href="">Charitable Activity </a>
</div>
<div>
<a href="">HighTech Activity</a>
</div>
<div>
<a href="">Constructive Activity</a>
</div>
<div>
<a href="">Justfor 
Fun</a>
</div>
<div>
<a href="">Special Activity</a>
</div>
</div>
<div className="bottomobile">
  <Drpdwn3/>
</div>
</div>

<header>
            <h1>
                Popular Activites
            </h1>
        </header>
<div className='activity-cards'>



<Card
           activityimg='./images/Acimg1.png'
           cardname='Football'
           location='Goa, Dumas Beach'
           rupee='50,000'
           persion='11-12'
           duration='6 Hours'
           />
       <Card
           activityimg='./images/Acimg1.png'
           cardname='Football'
           location='Goa, Dumas Beach'
           rupee='50,000'
           persion='11-12'
           duration='6 Hours'
           /><Card
           activityimg='./images/Acimg1.png'
           cardname='Football'
           location='Goa, Dumas Beach'
           rupee='50,000'
           persion='11-12'
           duration='6 Hours'
           /><Card
           activityimg='./images/Acimg1.png'
           cardname='Football'
           location='Goa, Dumas Beach'
           rupee='50,000'
           persion='11-12'
           duration='6 Hours'
           /><Card
           activityimg='./images/Acimg1.png'
           cardname='Football'
           location='Goa, Dumas Beach'
           rupee='50,000'
           persion='11-12'
           duration='6 Hours'
           /><Card
           activityimg='./images/Acimg1.png'
           cardname='Football'
           location='Goa, Dumas Beach'
           rupee='50,000'
           persion='11-12'
           duration='6 Hours'
           />    

</div>

   <Footer/>
  </>
  )
}

export default Activity;