import React from 'react';
import { Link } from 'react-router-dom';
import Navbaar from '../Navbaar';
import './Signup.css'




class CreateAccount extends React.Component{
  render(){
    return(
        <>
      <div className='loginimg'>
      <Link to="/home">
            <img src="./images/Logo.png" alt="" />
          </Link>
      </div>
              <div id="loginform">
        <FormHeader title="Create a new account" />
        
        <br />
        <Form />

      </div>
        </>

    )
  }
}

const FormHeader = props => (
  <>
    <h2 id="headerTitle">{props.title}</h2>

    </>
);


const Form = props => (
   <div>
     <FormInput description="Username" placeholder="Create your username" type="text" />
     <table>
        <td>
            <th>
            <FormInput description="Email" placeholder="Enter your email" type="email" />
            </th>
            <th>
            <FormInput description="Mobile Number" placeholder="Enter your Mobile Number" type="=tel" />
            </th>
        </td>
     </table>
    

     <FormInput description="Password" placeholder="Create your password" type="password"/>
     <FormButton title="Sign Up"/>
     <Link to="/login" className='otherm'>
     Already have an account?
     </Link> 
   </div>
);

const FormButton = props => (
  <div id="button" class="row">
    <button>{props.title}</button>
  </div>
);

const FormInput = props => (
  <div class="row">
    <label>{props.description}</label>
    <input type={props.type} placeholder={props.placeholder}/>
  </div>  
);





export default CreateAccount;