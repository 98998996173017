import React from 'react'
import Navbaar from '../Navbaar';
import Footer from './Footer';
import './Activity.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from "react-router-dom";
import { render } from '@testing-library/react';


function ActivityFilter() {

    let params = useParams();
    const ID = params.id;
    const API="https://codeedge.in/tacAdventure/api/activity";
    const [data, setData] = useState([])
    useEffect((params) => {
        
        console.log(ID)
        fetch(`${API}?id=${ID}`).then((result) => {
            result.json().then((resp) => {
                setData(resp)
            })
        })
    }, [])
    // console.log(data.activityName);
   render()
   { return (
        <>
            <Navbaar />
            <br /><br /><br /><br />
          

            {


             
                
                    <div className="mainfilter-container" >
                        {/* filter-container start*/}
                        <div className="activity-filter-main-box">
                            <div className="acivity-filter-box">
                                <table>
                                    <tr>
                                        <td> DURATION
                                            <table>
                                                <tr>
                                                    <td><p>Minimum</p></td>
                                                    <td><p>Maximum</p></td>
                                                </tr>
                                                <tr>
                                                    <td> <p>{data.timing_min}</p> </td>
                                                    <td> <p>{data.timing_max}</p></td>
                                                </tr>
                                                <tr>
                                                    <td> <p>Minutes</p> </td>
                                                    <td> <p>Minutes</p></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="acivity-filter-box">
                                <table>
                                    <tr>
                                        <td> AVAILABALE MODE
                                            <table>
                                                <tr>
                                                    <td><i class="bi bi-laptop"></i></td>
                                                    <td><i class="bi bi-building"></i></td>
                                                    <td><i class="bi bi-easel-fill"></i></td>
                                                </tr>
                                                <tr>
                                                    <td> <p>Virtual</p> </td>
                                                    <td> <p>Outdoor</p></td>
                                                    <td> <p>Class Room</p></td>
                                                </tr>
                                                <tr>
                                                    <td> <p>Y</p> </td>
                                                    <td> <p>N</p></td>
                                                    <td> <p>Y</p></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="acivity-filter-box">
                                <table>
                                    <tr>
                                        <td> PARTICIPANTS
                                            <table>
                                                <tr>
                                                    <td><i class="bi bi-person-dash-fill"></i></td>
                                                    <td><i class="bi bi-person-plus-fill"></i></td>

                                                </tr>
                                                <tr>
                                                    <td> <p>MIN</p> </td>
                                                    <td> <p>MAX</p></td>

                                                </tr>
                                                <tr>
                                                    <td> <p>{data.people_min}</p> </td>
                                                    <td> <p>{data.people_max}</p></td>

                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="acivity-filter-box">
                                <table>
                                    <tr>
                                        <td> TEAM SIZE
                                            <table>
                                                <tr>
                                                    <td><i class="bi bi-person-fill"></i></td>
                                                    <td><i class="bi bi-people-fill"></i></td>

                                                </tr>
                                                <tr>
                                                    <td> <p>MIN</p> </td>
                                                    <td> <p>MAX</p></td>

                                                </tr>
                                                <tr>
                                                    <td> <p>{data.teamsize_min}</p> </td>
                                                    <td> <p>{data.teamsize_max}</p></td>

                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="acivity-filter-box boxy">
                                <table>
                                    <tr>
                                        <td> PLAYING MODE
                                            <table>
                                                <tr>
                                                    <td><i class="bi bi-person-fill"></i></td>
                                                    <td><i class="bi bi-people-fill"></i></td>

                                                </tr>
                                                <tr>
                                                    <td><p>INDIVIDUALS</p> </td>
                                                    <td> <p>TEAMS</p></td>

                                                </tr>
                                                <tr>
                                                    <td> <p>Y</p> </td>
                                                    <td> <p>Y</p></td>

                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        {/* filter-container-end */}

                        {/* filter-about-section-start */}

                        <div className="filter-main-container">
                            <div className="filter-data-text">
                                <h1>{data.activityName}</h1>
                                <p>{data.about}</p>
                            </div>
                            <div className="filter-data-img">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZJKwAcJQ-PzDX21ifchItgKSWhYWCBwAtSg&usqp=CAU" alt="" />
                            </div>
                        </div>

                        {/* filter-about-section-end */}
                    </div>

                
            }

            
        </>
   
    );
   }
}

export default ActivityFilter;