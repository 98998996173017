import Dropdown from 'react-bootstrap/Dropdown';

function Drpdwn3() {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic">
      Categories
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Dropdown.Item href="#/action-1">Show all</Dropdown.Item>
        <Dropdown.Item href="#/action-1">Indoor Activity</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Outdoor Activity</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Indoor Activity</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Culinary Activity</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Charitable Activity</Dropdown.Item>
        <Dropdown.Item href="#/action-3">HighTech Activity</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Constructive Activity</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Just for Fun </Dropdown.Item>
        <Dropdown.Item href="#/action-3">Special Activity</Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Drpdwn3;